<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Invoices
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ invoices
                </div>
              </div>
<!--              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createProduct()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add Exam
                  </v-btn>
                  <v-btn
                      @click="exportPrice()"
                      class="ml-4 btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;"
                  >
                    <i class="fa fa-file-csv"></i>
                    Export Price CSV
                  </v-btn>
                  <v-btn
                      @click="importPrice()"
                      class="ml-4 btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;"
                  >
                    <i class="fa fa-file-csv"></i>
                    Import Price CSV
                  </v-btn>
                </div>
              </div>-->
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6"  :md="currentUser.access_type == 'score' ? 5 : 3" >
                  <v-text-field
                      label="Lookup by name, transaction IDs, or invoice no"
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchInvoice()"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select label="Payment method" :items="payment_methods" v-model="search.payment_method" item-value="value" item-text="text" dense outlined clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select label="Payment Status" :items="payment_status" v-model="search.payment_status" item-value="value" item-text="text" dense outlined clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.current_score_id"
                      v-on:keyup.enter="searchInvoice"
                      @input="search.current_score_id = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="id"
                      :items="scores"
                      outlined
                      dense
                      clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                      @click="searchInvoice()"
                      class="btn btn-primary btn-search"
                      :isloading="isloading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isloading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isloading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!isloading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 370px !important; white-space: pre-wrap;">Date</th>
                  <th class="px-3" style="max-width: 200px !important; white-space: pre-wrap;">Transaction Ref. No.</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enroller </th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoice to</th>
                  <th class="px-3">	Payment method</th>
                  <th class="px-3">Amount paid</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Billing address</th>
                  <th class="px-3">Payment Status</th>
                  <th>SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in invoices">
                    <td class="px-3 " style="max-width: 370px !important; white-space: pre-wrap;"> 
                       <span class="font-weight-bold">
                        {{ item.invoice_date  ? item.invoice_date  : 'N/A' }}
                      </span>
                    </td>
                    <td class="px-3 " style="max-width: 200px !important; white-space: pre-wrap;">
                        {{ item.transaction_id  ? item.transaction_id  : 'N/A' }}
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <a  @click="editAccountHolder(item.user_id)">
                        {{ item.full_name }}
                      </a>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <p v-html="item.invoiced_name"></p>
                      <div class="mt-1" v-if="item.invoice_no">
                        <strong>Invoice no:</strong>
                        {{ item.invoice_no }}
                      </div>
                    </td>
                    <td class="px-2">
                      <span>
                        <img :src="item.payment_method_icon" alt="" style="width: 30px"> {{item.payment_method_text}}
                      </span>
                    </td>
                    <td class="px-2">
                      <p class="body-1 font-weight-medium">
                      {{item.currency_symbol ? item.currency_symbol :'$'}}{{ item.grand_total_amount }}
                      </p>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <p v-html="item.full_address ? item.full_address : 'N/A' "></p>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.payment_status_text=='Completed', 'badge-warning': item.payment_status_text=='Pending' }"
                      >{{ item.payment_status_text }}</span>
                    </td>
                    <td>

                      {{item.score_short_name ? item.score_short_name : 'NA' }}

                    </td>
                    <td class="pr-0 text-center">
                      <a class="navi-link" @click.prevent="viewInvoice(item.cart_id,item.user_id)">
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                        <span class="navi-text"> View</span>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="invoices.length == 0">
                    <td colspan="8" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="invoices.length > 0"
                  class="pull-right mt-7"
                  @input="getAllInvoice"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="isloading"
              ></b-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>

  </v-app>
</template>
<script>
import InvoiceService from "@/services/invoice/InvoiceService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
const accountHolder=new AccountholderService();
const score = new ScoreService();
const invoice=new InvoiceService();
export default {
  data(){
    return{
      isloading:false,
      invoices:[],
      scores: [],
      payment_methods:[
        {value:'card',text:'Card'},
        {value:'paypal',text:'PayPal'},
        {value:'schoolpay',text:'SchoolPay'},
        {value:'alepay',text:'AlePay'},
        {value:'other',text:'Other'},
      ],
      payment_status:[
        {value:'completed',text:'Completed'},
        {value:'pending',text:'Pending'},
      ],
      page:'',
      total:'',
      perPage:'',
      search:{
        info:'',
        payment_method:'',
        payment_status:'',
      }
    }
  },
  methods:{
    editAccountHolder(accountholderId){
      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: accountholderId }
      });
    },
    searchInvoice(){
      this.getAllInvoice();
    },
    getAllInvoice(){
      this.isloading=true;
      invoice
          .paginate(this.search,this.page)
          .then(response => {
            this.invoices=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          }) .catch((err) => {
           })
          .finally(() => {
            this.isloading=false;
          });
    },
    viewInvoice(cartId,userId) {
      accountHolder
          .viewInvoice(userId, cartId);
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.getAllInvoice();
    this.getAllScore();
  }
}
</script>